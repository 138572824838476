<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    <span class="d-none d-sm-inline-block">Todos los derechos reservados</span> &copy;AsedInfo {{ year }}
  </span>
  <span class="float-md-right d-block d-md-inline-block mt-25">
    <img src="assets/images/footer/GobiernoDelEncuentro.svg" class="rounded float-right img-fluid" style="width:200px;">
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>